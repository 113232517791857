/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
::v-deep .rich-text-container {
  max-height: 600px;
  overflow: auto;
  border: 1px solid #ccc;
  padding: 0 10px;
}
::v-deep .rich-text-container img {
  cursor: zoom-in;
}
::v-deep .ant-form-item-control-wrapper {
  overflow: hidden;
}
